import React from 'react';
import { DatePicker, Space } from 'antd';

const DateComponent = (props) => {
    return(
        <div>
            <Space direction="vertical" style={{ color: "red" }}>
                <DatePicker format="MM/DD/YYYY" value={props.value} disabledDate={d=>d.isBefore(props.issueDate)} onChange={props.onChangeDate} />
            </Space>
        </div>
    )
}   

export default DateComponent;